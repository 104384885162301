import React from 'react';
import 'react-circular-progressbar/dist/styles.css';

import { CategoryList, ProvisionAnswer } from '@pdai/shared';
import { Table } from 'reactstrap';
import { Plan } from '../../helpers/firebase_helper';

interface ComparisonTableProps {
  plans: Plan[];
  selectedCategories: string[];
}

const ComparisonTable: React.FC<ComparisonTableProps> = ({ plans, selectedCategories }) => {
  const getProvisionNamesAndAnswers = (): {
    allProvisionNames: { name: string; category: string; label: string }[];
    plansAnswers: { [planId: string]: { [key: string]: ProvisionAnswer } };
  } => {
    const allProvisionNames: { name: string; category: string; label: string }[] = [];
    const seenProvisionNames = new Set<string>();
    const plansAnswers: { [planId: string]: { [key: string]: ProvisionAnswer } } = {};

    // Helper function to add provision names to the list while preserving order
    const addProvisionName = (name: string, category: string, label: string) => {
      if (!seenProvisionNames.has(name)) {
        allProvisionNames.push({ name, category, label });
        seenProvisionNames.add(name);
      }
    };

    // 1. Get all unique provision names from all plans, preserving order
    plans.forEach((plan) => {
      plan.answers?.forEach((answer) => {
        addProvisionName(answer.provisionName, answer.category, answer.provisionLabel);
      });

      // 2. Create dictionaries of answers for each plan
      const planAnswers: { [key: string]: ProvisionAnswer } = {};
      plan.answers?.forEach((answer) => {
        planAnswers[answer.provisionName] = answer;
      });
      plansAnswers[plan.id!] = planAnswers;
    });

    return { allProvisionNames, plansAnswers };
  };

  const { allProvisionNames, plansAnswers } = getProvisionNamesAndAnswers();

  return (
    <>
      <Table className='m-3 provision-table-comparison'>
        {CategoryList.map((category) => {
          return selectedCategories.length === 0 || selectedCategories.includes(category) ? (
            <tbody key={category}>
              <tr key='firstHeader'>
                <th className='px-4 provision-category-header' colSpan={plans.length + 1}>
                  {category}
                </th>
              </tr>
              <tr key='secondHeader'>
                <th className='px-4 provision-label-header'>Provision</th>
                {plans.map((plan) => {
                  return (
                    <th className='provision-label-header'>{plan.documentName || plan.fileName}</th>
                  );
                })}
              </tr>
              {allProvisionNames.map((provision) => {
                if (provision.category == category) {
                  return (
                    <tr key={provision.name} className='align-middle'>
                      <td className='px-4'>{provision.label}</td>

                      {plans.map((plan) => {
                        return (
                          <td>
                            <span
                              className={
                                plansAnswers[plan.id!][provision.name]?.userAnswer
                                  ? 'edited-provision'
                                  : ''
                              }
                            >
                              {plansAnswers[plan.id!][provision.name]?.userAnswer ||
                                plansAnswers[plan.id!][provision.name]?.modelAnswer ||
                                ''}
                            </span>
                          </td>
                        );
                      })}
                    </tr>
                  );
                }
              })}
            </tbody>
          ) : (
            <></>
          );
        })}
      </Table>
    </>
  );
};

export default ComparisonTable;
