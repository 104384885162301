import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Card, Col, Form, Row } from 'reactstrap';
import { getFirebaseBackend } from '../../firebase';

interface UploadFileDropZoneProps {
  onCompleteUpload: () => void;
}

const UploadFileDropZone: React.FC<UploadFileDropZoneProps> = ({ onCompleteUpload }) => {
  const [selectedFile, setSelectedFile] = useState<any | null>(null);

  function handleAcceptedFiles(file: any) {
    Object.assign(file, {
      formattedSize: formatBytes(file.size),
    });
    setSelectedFile(file);
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const firebaseHelper = getFirebaseBackend();
    firebaseHelper.uploadFileToStorage(selectedFile);
    console.log('File uploaded successfully:', selectedFile.name);
    onCompleteUpload();
  };

  const handleDelete = () => {
    setSelectedFile(null);
  };

  return (
    <Form className='pb-4'>
      <Dropzone
        multiple={false}
        accept={{ 'application/pdf': ['.pdf'] }}
        onDrop={(acceptedFile: any) => {
          handleAcceptedFiles(acceptedFile[0]);
        }}
      >
        {({ getRootProps, getInputProps }: any) => (
          <div className='upload-file-dropzone'>
            <div className='dz-message needsclick mt-2' {...getRootProps()}>
              <input {...getInputProps()} />
              <div className='mb-3'>
                <i className='display-4 text-muted bx bxs-cloud-upload' />
              </div>
              <h4>Drag and drop the file here or click to upload.</h4>
            </div>
          </div>
        )}
      </Dropzone>
      <div className='mt-3'>
        {selectedFile && (
          <div>
            <Card className='mt-1 mb-0 shadow-none border'>
              <div className='p-2'>
                <Row className='align-items-center'>
                  <Col className='col-11 text-start'>
                    <p className='fs-5 mb-0'>{selectedFile.name}</p>
                    <p className='mb-0'>{selectedFile.formattedSize}</p>
                  </Col>
                  <Col className='col-1 text-end'>
                    <i className='mdi mdi-delete-outline delete-file-icon' onClick={handleDelete} />
                  </Col>
                </Row>
              </div>
            </Card>
            <div className='text-center'>
              <Button
                type='button'
                color='secondary'
                className='btn mt-3'
                onClick={() => {
                  handleFileUpload();
                }}
              >
                Upload Document
              </Button>
            </div>
          </div>
        )}
      </div>
    </Form>
  );
};

export default UploadFileDropZone;
