import { createSlice } from '@reduxjs/toolkit';

import { Plan } from '@helpers/firebase_helper';
import {
  fetchPlanById,
  fetchPlansByIds,
  selectPlan,
  updatePlan,
  updatePlanProvision,
} from './thunk';

interface PlansState {
  plans: Plan[];
  plansForComparison: Plan[];
  planIds: string[];
  selectedPlanIds: string[];
  selectedPlanDetails: Plan | null;
  loading: boolean;
  error: string | null;
}

const initialState: PlansState = {
  plans: [],
  plansForComparison: [],
  planIds: [],
  selectedPlanIds: [],
  selectedPlanDetails: null,
  loading: false,
  error: null,
};

const PlansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlans: (state, action) => {
      state.plans = action.payload;
      state.loading = false;
    },
    setLoadingPlans: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePlan.pending, (state) => {
        state.error = null;
      })
      .addCase(updatePlan.fulfilled, (state, action) => {
        if (action.payload.status === 'deleted') {
          state.plans = state.plans.filter((plan) => plan.id !== action.payload.id);
          if (state.selectedPlanDetails?.id === action.payload.id) {
            state.selectedPlanDetails = null;
          }
        } else {
          state.plans = [
            ...state.plans.map((plan) =>
              plan.id === action.payload.id ? { ...plan, ...action.payload } : plan,
            ),
          ];
          state.selectedPlanDetails = { ...state.selectedPlanDetails!, ...action.payload };
        }
        state.loading = false;
      })
      .addCase(updatePlan.rejected, (state, action) => {
        state.error = action.error.message || 'Error updating plan';
      })
      .addCase(selectPlan.fulfilled, (state, action) => {
        if (state.selectedPlanIds.includes(action.payload)) {
          const index = state.selectedPlanIds.indexOf(action.payload);
          state.selectedPlanIds.splice(index, 1);
        } else {
          state.selectedPlanIds.push(action.payload);
        }
        state.loading = false;
      })
      .addCase(fetchPlanById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlanById.fulfilled, (state, action) => {
        state.selectedPlanDetails = action.payload;
        state.loading = false;
      })
      .addCase(fetchPlanById.rejected, (state, action) => {
        state.error = action.error.message || 'Error fetching plan by ID';
        state.loading = false;
      })
      .addCase(fetchPlansByIds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPlansByIds.fulfilled, (state, action) => {
        state.plansForComparison = action.payload;
        state.loading = false;
      })
      .addCase(fetchPlansByIds.rejected, (state, action) => {
        state.error = action.error.message || 'Error fetching plans by IDs';
        state.loading = false;
      })
      .addCase(updatePlanProvision.pending, (state) => {
        state.error = null;
      })
      .addCase(updatePlanProvision.fulfilled, (state, action) => {
        state.selectedPlanDetails = {
          ...state.selectedPlanDetails!,
          answers: [
            ...state.selectedPlanDetails!.answers!.map((oldProvision: any) =>
              oldProvision.provisionName === action.payload.provision.provisionName
                ? action.payload.provision
                : oldProvision,
            ),
          ],
        };
      })
      .addCase(updatePlanProvision.rejected, (state, action) => {
        state.error = action.error.message || 'Error saving provision review.';
      });
  },
});

export const { setPlans, setLoadingPlans } = PlansSlice.actions;

export default PlansSlice.reducer;
