import { FirebaseHelper } from './helpers/firebase_helper';

let _fireBaseBackend: FirebaseHelper | null = null;

const initFirebaseBackend = () => {
  if (!_fireBaseBackend) {
    // PROD Firebase config.
    let firebaseConfig = {
      apiKey: process.env.REACT_APP_APIKEY,
      authDomain: process.env.REACT_APP_AUTHDOMAIN,
      databaseURL: process.env.REACT_APP_DATABASEURL,
      projectId: process.env.REACT_APP_PROJECTID,
      storageBucket: process.env.REACT_APP_STORAGEBUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
      appId: process.env.REACT_APP_APPID,
      measurementId: process.env.REACT_APP_MEASUREMENTID,
    };

    // For local development pointing to DEV environment.
    if (process.env.REACT_APP_SERVER_ENVIRONMENT === 'DEV') {
      firebaseConfig = {
        apiKey: process.env.REACT_APP_APIKEY_DEV,
        authDomain: process.env.REACT_APP_AUTHDOMAIN_DEV,
        databaseURL: process.env.REACT_APP_DATABASEURL_DEV,
        projectId: process.env.REACT_APP_PROJECTID_DEV,
        storageBucket: process.env.REACT_APP_STORAGEBUCKET_DEV,
        messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID_DEV,
        appId: process.env.REACT_APP_APPID_DEV,
        measurementId: process.env.REACT_APP_MEASUREMENTID_DEV,
      };
    }

    _fireBaseBackend = new FirebaseHelper(firebaseConfig);
  }
  return _fireBaseBackend;
};

const getFirebaseBackend = (): FirebaseHelper => {
  if (!_fireBaseBackend) {
    throw new Error('FirebaseHelper not initialized. Call initFirebaseBackend first.');
  }
  return _fireBaseBackend;
};

export { getFirebaseBackend, initFirebaseBackend };
