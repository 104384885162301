import { Plan } from '@helpers/firebase_helper';
import { ProvisionAnswer } from '@pdai/shared';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { collection, doc, getDoc, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { getFirebaseBackend } from '../../firebase';
import { setLoadingPlans, setPlans } from './reducer';

export const updatePlan = createAsyncThunk(
  'plans/update',
  async ({
    id,
    updatedData,
  }: {
    id: string;
    updatedData: Partial<Plan>;
  }): Promise<Partial<Plan>> => {
    const response = getFirebaseBackend().updatePlan(id, updatedData);
    return response;
  },
);

export const selectPlan = createAsyncThunk(
  'plans/select',
  async (selectedId: string): Promise<string> => {
    return selectedId;
  },
);

export const subscribeToPlans = createAsyncThunk('plans/subscribe', async (_, { dispatch }) => {
  try {
    dispatch(setLoadingPlans(true));
    const firebaseHelper = getFirebaseBackend();
    const currentUser = firebaseHelper.getCurrentUser();

    const q = query(
      collection(firebaseHelper.db, 'adoptionAgreements'),
      where('userId', '==', currentUser.uid),
      where('status', '!=', 'deleted'),
      orderBy('createdAt', 'desc'), // Order by creation time
    );

    // Unsubscribe function to stop listening for updates
    const unsubscribe = onSnapshot(
      q,
      async (snapshot) => {
        const updatedDocuments = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const planData = doc.data() as Plan;

            if (planData.fileUri) {
              const storageRef = ref(
                firebaseHelper.storage,
                planData.fileUri.replace(
                  'gs://' + firebaseHelper.firebaseConfig.storageBucket + '/',
                  '',
                ),
              );
              const downloadUrl = await getDownloadURL(storageRef);

              return {
                ...planData,
                id: doc.id,
                downloadUrl,
              };
            } else {
              return {
                ...planData,
                id: doc.id,
              };
            }
          }),
        );

        dispatch(setPlans(updatedDocuments));
      },
      (error) => {
        console.error('Error fetching documents:', error);
      },
    );
    return unsubscribe;
  } catch (error) {
    console.error('Error subscribing to documents:', error);
    throw error;
  }
});

export const updatePlanProvision = createAsyncThunk(
  'plans/updatePlanProvision',
  async ({
    provision,
    planId,
  }: {
    provision: ProvisionAnswer;
    planId: string;
  }): Promise<{ provision: ProvisionAnswer; planId: string }> => {
    await getFirebaseBackend().updatePlanWithProvisionAnswer(provision, planId);
    return { provision, planId };
  },
);

// Fetch multiple plans by their IDs
export const fetchPlansByIds = createAsyncThunk(
  'plans/fetchPlansByIds',
  async (planIds: string[]): Promise<Plan[]> => {
    try {
      const firebaseHelper = getFirebaseBackend();
      const plans: Plan[] = [];

      // Fetch each plan individually
      for (const planId of planIds) {
        const planDoc = await getDoc(doc(firebaseHelper.db, 'adoptionAgreements', planId));
        if (!planDoc.exists) {
          throw new Error('Plan not found: ' + planId);
        }

        const planData = planDoc.data() as Plan;
        plans.push({
          ...planData,
          id: planDoc.id,
        });
      }
      return plans;
    } catch (error) {
      console.error('Error fetching plans by IDs:', error);
      throw error;
    }
  },
);

// Fetch a specific plan by ID
export const fetchPlanById = createAsyncThunk(
  'plans/fetchById',
  async (planId: string): Promise<Plan> => {
    try {
      const firebaseHelper = getFirebaseBackend();

      // Fetch the plan from Firestore
      const planDoc = await getDoc(doc(firebaseHelper.db, 'adoptionAgreements', planId));
      if (!planDoc.exists) {
        throw new Error('Plan not found: ' + planId);
      }

      const planData = planDoc.data() as Plan;

      if (planData.fileUri) {
        const storageRef = ref(
          firebaseHelper.storage,
          planData.fileUri.replace('gs://' + firebaseHelper.firebaseConfig.storageBucket + '/', ''),
        );
        const downloadUrl = await getDownloadURL(storageRef);

        return {
          ...planData,
          id: planDoc.id,
          downloadUrl,
        };
      } else {
        return {
          ...planData,
          id: planDoc.id,
        };
      }
    } catch (error) {
      console.error('Error fetching plan by ID:', error);
      throw error;
    }
  },
);
