import { ProvisionAnswer } from '@pdai/shared';
import { initializeApp } from 'firebase/app';
import {
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
} from 'firebase/auth';

import {
  addDoc,
  collection,
  doc,
  FieldValue,
  getDoc,
  getFirestore,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage, ref, uploadBytes } from 'firebase/storage';

export interface Plan {
  id?: string;
  userId: string;
  documentName?: string;
  fileName: string;
  fileUri?: string;
  downloadUrl?: string;
  answers?: ProvisionAnswer[];
  prompt?: string;
  status: string;
  errorMessage?: string;
  processedAt?: string;
  updatedAt: string | FieldValue;
  createdAt: string | FieldValue;
}

class FirebaseHelper {
  readonly storage;
  readonly auth;
  readonly db;
  readonly functions;
  readonly planFilesCollection;
  readonly firebaseConfig;

  constructor(firebaseConfig: any) {
    if (firebaseConfig) {
      // Initialize Firebase
      this.firebaseConfig = firebaseConfig;
      const app = initializeApp(firebaseConfig);

      this.storage = getStorage(app);
      this.auth = getAuth(app);
      this.functions = getFunctions(app);

      // Initialize Cloud Firestore and get a reference to the service
      this.db = getFirestore(app);
      this.planFilesCollection = collection(this.db, 'planFiles');

      onAuthStateChanged(this.auth, (user: any) => {
        if (user) {
          localStorage.setItem('authUser', JSON.stringify(user));
        } else {
          localStorage.removeItem('authUser');
        }
      });
    }
  }

  registerUser = (email: any, password: any) => {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(this.auth, email, password).then(
        (user: any) => {
          resolve(this.auth.currentUser);
        },
        (error: any) => {
          reject(this._handleError(error));
        },
      );
    });
  };

  // Basic document analysis
  async editProfileAPI(userName: string): Promise<any> {
    try {
      const currentUser = this.auth.currentUser;
      if (currentUser) {
        await updateProfile(currentUser, { displayName: userName });
        return userName;
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error editing profile username:', error);
      throw error;
    }
  }

  loginUser = async (email: any, password: any) => {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(this.auth, email, password).then(
        (user: any) => {
          resolve(this.auth.currentUser);
        },
        (error: any) => {
          reject(this._handleError(error));
        },
      );
    });
  };

  forgetPassword = (email: any) => {
    return new Promise((resolve, reject) => {
      console.log(
        'forgetPassword',
        window.location.protocol + '//' + window.location.host + '/login',
      );
      sendPasswordResetEmail(this.auth, email, {
        url: window.location.protocol + '//' + window.location.host + '/login',
      })
        .then(() => {
          resolve(true);
        })
        .catch((error: any) => {
          console.log('error:', error);
          reject(this._handleError(error));
        });
    });
  };

  logout = () => {
    return new Promise((resolve, reject) => {
      signOut(this.auth)
        .then(() => {
          resolve(true);
        })
        .catch((error: any) => {
          reject(this._handleError(error));
        });
    });
  };

  socialLoginUser = async (type: any) => {
    let provider: any;
    if (type === 'google') {
      provider = new GoogleAuthProvider();
    } else if (type === 'facebook') {
      provider = new FacebookAuthProvider();
    }
    try {
      const result = await signInWithPopup(this.auth, provider);
      const user = result.user;
      return user;
    } catch (error) {
      throw this._handleError(error);
    }
  };

  addNewUserToFirestore = (user: any) => {
    const collection: any = this.db.collection('users');
    const { profile } = user.additionalUserInfo;
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: this.db.FieldValue.serverTimestamp(),
      lastLoginTime: this.db.FieldValue.serverTimestamp(),
    };
    collection.doc(this.auth.currentUser.uid).set(details);
    return { user, details };
  };

  // Upload File to Firebase
  async uploadFileToStorage(file: File) {
    try {
      const newFile: Plan = {
        userId: this.auth.currentUser.uid,
        fileName: file.name,
        status: 'uploading',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      };

      const docRef = await addDoc(collection(this.db, 'adoptionAgreements'), newFile);

      const metadata = {
        contentType: 'application/pdf',
        customMetadata: {
          documentId: docRef.id,
          fileType: 'planFile',
        },
      };

      // planFiles/USERID/DOCID/PDF_FILE.
      const filePath = `planFiles/${this.auth.currentUser.uid}/${docRef.id}/${file.name}`;
      console.log('filePath', filePath);
      const storageRef = ref(this.storage, filePath);
      await uploadBytes(storageRef, file, metadata);
    } catch (error) {
      console.log(error);
    }
  }

  async updatePlan(id: string, updatedData: Partial<Plan>): Promise<Partial<Plan>> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        const docRef = doc(this.db, 'adoptionAgreements', id);
        await updateDoc(docRef, { ...updatedData });
        return { id, ...updatedData };
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error updating plans:', error);
      throw error;
    }
  }

  async updatePlanWithProvisionAnswer(
    newProvisionAnswer: ProvisionAnswer,
    planId: string,
  ): Promise<Partial<Plan>> {
    try {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        // Assuming there's only one matching document
        const docRef = doc(this.db, 'adoptionAgreements', planId);
        const planToBeUpdated = await getDoc(docRef);

        const updatedData = {
          answers: planToBeUpdated
            .data()!
            .answers.map((oldAnswer: any) =>
              oldAnswer.provisionName === newProvisionAnswer.provisionName
                ? newProvisionAnswer
                : oldAnswer,
            ),
          updatedAt: Timestamp.now().toDate().toISOString(),
        };

        await updateDoc(docRef, updatedData);

        return updatedData;
      } else {
        throw new Error('User not authenticated');
      }
    } catch (error) {
      console.error('Error updating plan with provision answer:', error);
      throw error;
    }
  }

  getCurrentUser() {
    const user = localStorage.getItem('authUser');

    if (!user) {
      throw new Error('User not authenticated');
    }
    return JSON.parse(user);
  }

  _handleError(error: any) {
    const errorMessage = error.message;
    return errorMessage;
  }
}

export { FirebaseHelper };
