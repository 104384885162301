import { Plan } from '@helpers/firebase_helper';
import { CategoryList } from '@pdai/shared';
import Spinners from 'Components/Common/Spinner';
import { AppDispatch } from 'index';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from 'reactstrap';
import { fetchPlansByIds } from 'slices/plan/thunk';
import Breadcrumbs from '../../Components/Common/Breadcrumb';
import ComparisonTable from './comparisonTable';

const PlanComparison = () => {
  document.title = 'PlanPort - Plan Comparison';
  const navigate = useNavigate();
  const { ids } = useParams();
  const planIds = ids!.split(',');

  const dispatch: AppDispatch = useDispatch();

  const { plans, loading } = useSelector((state: any) => ({
    plans: state.Plan.plansForComparison as Plan[],
    loading: state.Plan.loading as boolean,
  }));

  useEffect(() => {
    dispatch(fetchPlansByIds(planIds));
  }, [dispatch]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleCategoryClick = (category: string) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  return (
    <div className='page-content'>
      <React.Fragment>
        <Container fluid>
          <Breadcrumbs title='My Plans' breadcrumbItem='Plan Comparison' />

          <Row>
            <Col xs={12}>
              <Card>
                {loading && <Spinners />}
                {/* TOP Section */}
                {!loading && plans && (
                  <div className='p-3'>
                    <div className='planDetail-top-section'>
                      <Row>
                        <Col className='col-12 text-end'>
                          <Button
                            type='button'
                            color='light'
                            className='btn px-5 my-0'
                            onClick={() => navigate(`/plans`)}
                          >
                            Back to My Plans
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                {!loading && plans && (
                  <Row className='p-3'>
                    <Col className='col-8'>
                      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <div className='d-flex'>
                          <DropdownToggle caret color='primary' className='category-filter'>
                            <Row>
                              <Col className='col-10 text-start'>Select Categories</Col>
                              <Col className='col-2 text-end'>
                                <i className='mdi mdi-chevron-down px-1' />
                              </Col>
                            </Row>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                setSelectedCategories([]);
                              }}
                            >
                              Clear Filter
                            </DropdownItem>
                            <div className='dropdown-divider'></div>
                            {CategoryList.map((category) => (
                              <DropdownItem
                                key={category}
                                onClick={() => handleCategoryClick(category)}
                                toggle={false}
                                disabled={
                                  selectedCategories.length >= 3 &&
                                  !selectedCategories.includes(category)
                                }
                              >
                                <Input
                                  type='checkbox'
                                  checked={selectedCategories.includes(category)}
                                />{' '}
                                {category}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                          <div className='mt-2 px-3'>{selectedCategories.join(', ')}</div>
                        </div>
                      </Dropdown>
                    </Col>
                  </Row>
                )}
                {/* Provisions table */}
                {!loading && plans && (
                  <ComparisonTable plans={plans} selectedCategories={selectedCategories} />
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  );
};

export default PlanComparison;
