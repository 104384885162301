import React from 'react';
import { Link } from 'react-router-dom';

//import components
import SidebarContent from './SidebarContent';

import logo from '../../assets/images/pdai/PlanPortLogoWhiteTransparentBackground.png';
import logoSquared from '../../assets/images/pdai/PlanPortLogoWhiteTransparentBackgroundSquared.png';

//import images

const Sidebar = (props: any) => {
  return (
    <React.Fragment>
      <div className='vertical-menu'>
        <div className='navbar-brand-box pdai-not-collapsed'>
          <Link to='/' className='logo logo-light'>
            <span>
              <img src={logo} alt='' height='70' />
            </span>
          </Link>
          <span className='beta-logo'>Beta</span>
        </div>

        <div className='navbar-brand-box pdai-collapsed'>
          <Link to='/' className='logo logo-light'>
            <span className='text-start'>
              <img src={logoSquared} alt='' height='40' />
            </span>
          </Link>
        </div>

        <div data-simplebar className='h-100'>
          {props.type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className='sidebar-background'></div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
