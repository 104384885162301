import * as PDFJS from 'pdfjs-dist';
import { useEffect, useRef, useState } from 'react';
import Spinners from '../Spinner';

export interface PdfProps {
  pdfUrl: string;
  setPdfDoc: React.Dispatch<any>;
  getPdfDoc: () => PDFJS.PDFDocumentProxy;
  refreshPDF: boolean;
}

const PDFJSViewer = ({ pdfUrl, setPdfDoc, getPdfDoc, refreshPDF }: PdfProps) => {
  PDFJS.GlobalWorkerOptions.workerSrc =
    'https://unpkg.com/pdfjs-dist@4.8.69/build/pdf.worker.min.mjs';

  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const pdfContainerRef = useRef(null);

  // loading PDF doc.
  useEffect(() => {
    setIsLoading(true);
    const loadPDFDocument = async () => {
      await PDFJS.getDocument(pdfUrl).promise.then((pdfDoc) => {
        setPdfDoc(pdfDoc);
      });
    };
    const containerForFullPdf = pdfContainerRef.current! as HTMLDivElement;
    containerForFullPdf.innerHTML = '';
    loadPDFDocument();
  }, [pdfUrl, refreshPDF]);

  // loading pages
  useEffect(() => {
    const loadPages = async () => {
      if (getPdfDoc()) {
        const containerForFullPdf = pdfContainerRef.current! as HTMLDivElement;

        // Loop from 1 to total_number_of_pages in PDF document
        for (let i = 1; i <= getPdfDoc().numPages; i++) {
          // Get desired page
          createAndLoadPage(getPdfDoc, i, containerForFullPdf);
        }
      }
      setIsLoading(false);
    };
    loadPages();
  }, [getPdfDoc()]);

  return (
    <>
      {isLoading && <Spinners />}
      <div
        ref={pdfContainerRef}
        id='containerForFullPdf'
        className={`pdfContainer ${isLoading && 'd-none'}`}
      ></div>
    </>
  );
};

export default PDFJSViewer;

const createAndLoadPage = (
  getPdfDoc: () => PDFJS.PDFDocumentProxy,
  i: number,
  containerForFullPdf: HTMLDivElement,
) => {
  getPdfDoc()
    .getPage(i)
    .then((page) => {
      const pageParent = document.createElement('div');
      pageParent.setAttribute('id', 'page-' + (page._pageIndex + 1));
      pageParent.className = 'pdfPageCanvas';
      containerForFullPdf.appendChild(pageParent);
      const canvas = document.createElement('canvas');
      canvas.setAttribute('id', 'canvas-page-' + (page._pageIndex + 1));
      pageParent.appendChild(canvas);
      setTimeout(() => {
        loadPageFromPDF(page, canvas, pageParent, containerForFullPdf);
      }, 200);
    });
};

const loadPageFromPDF = (
  page: PDFJS.PDFPageProxy,
  canvas: HTMLCanvasElement,
  pageParent: HTMLDivElement,
  containerFullPdf: HTMLDivElement,
) => {
  let viewport = page.getViewport({ scale: 1 });
  const newScale = (containerFullPdf.offsetWidth - 50) / viewport.width;
  viewport = page.getViewport({ scale: newScale });

  const context = canvas.getContext('2d');

  context!.clearRect(0, 0, canvas.width, canvas.height);
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  const renderContext = {
    canvasContext: context!,
    viewport: viewport,
  };

  page
    .render(renderContext)
    .promise.then(function () {
      return page.getTextContent();
    })
    .then((textContent) => {
      const textLayerDiv = document.createElement('div');
      textLayerDiv.setAttribute('class', 'textLayer');
      pageParent.appendChild(textLayerDiv);

      const textLayer = new PDFJS.TextLayer({
        container: textLayerDiv,
        textContentSource: textContent,
        viewport: viewport,
      });

      textLayer.render();
    });
};
