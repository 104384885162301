import { AppDispatch } from 'index';
import React, { useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Spinner, Table } from 'reactstrap';
import { updatePlan } from 'slices/thunk';
import { Plan } from '../../helpers/firebase_helper';
import EditDocumentNameModal from './editDocumentNameModal';
import {
  canDownloadFileOrViewAnswers,
  canEditPlanName,
  canSelectPlanCheckbox,
  getStatusDescription,
  shouldShowSpinnerStatus,
} from './planHelper';

interface PlansListProps {
  plans: Plan[];
  selectedPlanIds: string[];
  handleSelect: (id: string) => void;
  handleViewAnswers: (answers: any) => void;
}

const PlanTable: React.FC<PlansListProps> = ({ plans, selectedPlanIds, handleSelect }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [editedPlan, setEditedPlan] = useState<Plan>();
  const [showEditDocumentNameModal, setShowEditDocumentNameModal] = useState(false);

  const handleCheckboxClick = (event: any) => {
    const planId = event.target.value;
    handleSelect(planId);
  };

  const handleDeleteFailed = (planId) => {
    dispatch(updatePlan({ id: planId, updatedData: { status: 'deleted' } }));
  };

  return (
    <div className='table-responsive px-3 aa-list-container'>
      <Table className='table mb-3'>
        <thead className='table-light border'>
          <tr>
            <th className='checkbox-agreement-input-header'></th>
            <th>Document Name</th>
            <th>Status</th>
            <th className='text-center'></th>
          </tr>
        </thead>
        <tbody>
          {plans.map((plan: Plan) => {
            return (
              <tr key={plan.id}>
                <td>
                  <input
                    type='checkbox'
                    className='checkbox-agreement-input'
                    value={plan.id}
                    id={plan.id}
                    name='agreementcheckbox'
                    onChange={(e) => e.target.value}
                    onClick={handleCheckboxClick}
                    checked={selectedPlanIds.includes(plan.id!)}
                    disabled={!canSelectPlanCheckbox(plan.status)}
                  />
                  <label htmlFor={plan.id} className='toggle' />
                </td>
                <td>
                  <div className='checkbox-agreement-column'>
                    <Row>
                      <Col className='col-11'>{plan.documentName || plan.fileName}</Col>
                      <Col className='col-1 text-end'>
                        {canEditPlanName(plan.status) && (
                          <Button
                            type='button'
                            className='btn edit-provision-button'
                            onClick={async () => {
                              setEditedPlan(plan);
                              setShowEditDocumentNameModal(true);
                            }}
                          >
                            <i className='mdi mdi-pencil-box-outline'></i>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-start planTableStatusColumn'>
                    <span className={`aa-list-status-${plan.status}`}>
                      {getStatusDescription(plan.status)}
                      {shouldShowSpinnerStatus(plan.status) && (
                        <Spinner className='ms-2 small-spinner' />
                      )}
                    </span>
                  </div>
                </td>
                {canDownloadFileOrViewAnswers(plan.status) && (
                  <td className='text-end'>
                    <button
                      className='link-button px-4'
                      onClick={() => window.open(plan.downloadUrl, '_blank')}
                    >
                      Download
                    </button>
                    <button
                      className='link-button px-4'
                      onClick={() => navigate(`/plan/${plan.id}`)}
                    >
                      View
                    </button>
                  </td>
                )}
                {plan.status === 'failed' && (
                  <td className='text-danger'>
                    <div className='text-end'>
                      {plan.errorMessage}{' '}
                      <button
                        className='link-button-error px-4 text-end'
                        onClick={() => {
                          handleDeleteFailed(plan.id);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {editedPlan && (
        <EditDocumentNameModal
          isOpen={showEditDocumentNameModal}
          toggle={() => setShowEditDocumentNameModal(false)}
          plan={editedPlan}
        />
      )}
    </div>
  );
};

export default PlanTable;
