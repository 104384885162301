import { BreadcrumbItem, Col, Row } from 'reactstrap';

const Breadcrumb = (props: any) => {
  return (
    <Row>
      <Col className='col-12'>
        <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
          <h4 className='mb-sm-0 font-size-18'>{props.breadcrumbItem}</h4>
          <div className='page-title-right'>
            <ol className='breadcrumb m-0'>
              {props.title && <BreadcrumbItem active>{props.title}</BreadcrumbItem>}
              <BreadcrumbItem>{props.breadcrumbItem}</BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumb;
