import { ProvisionAnswer } from '@pdai/shared';

export const drawNewAnnotation = (
  provision: ProvisionAnswer,
  index: number,
  canvas: HTMLCanvasElement,
  canvasContext: CanvasRenderingContext2D,
) => {
  canvasContext.fillStyle = 'rgba(255, 255, 0, 0.1)';
  canvasContext.strokeStyle = 'rgba(255, 255, 0, 1)';

  const location = provision.locations[index];
  const bottomPosition = location.bottom * canvas.height;
  const topPosition = location.top * canvas.height;
  const { x, y, width, height } = {
    x: 0,
    y: topPosition - 5,
    width: canvas.width,
    height: bottomPosition - topPosition + 10,
  };
  canvasContext.fillRect(x, y, width, height);
  canvasContext.strokeRect(x, y, width, height);
  canvasContext.getImageData(0, 0, canvas.width, canvas.height);
  return { x, y };
};
