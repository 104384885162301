import { Plan } from '@helpers/firebase_helper';
import Spinners from 'Components/Common/Spinner';
import { Timestamp } from 'firebase/firestore';
import { AppDispatch } from 'index';
import { getStatusDescription } from 'pages/PlanList/planHelper';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Container, Row } from 'reactstrap';
import { fetchPlanById, updatePlan } from 'slices/thunk';
import * as XLSX from 'xlsx';
import Breadcrumbs from '../../Components/Common/Breadcrumb';
import ProvisionsTable from './provisionsTable';

const PlanDetails = () => {
  document.title = 'PlanPort - Plan Details';
  document.body.classList.add('vertical-collpsed');
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();

  const { plan, loading } = useSelector((state: any) => ({
    plan: state.Plan.selectedPlanDetails as Plan,
    loading: state.Plan.loading as boolean,
  }));

  const handleArchivePlan = () => {
    dispatch(updatePlan({ id: plan.id!, updatedData: { status: 'archived' } }));
  };

  useEffect(() => {
    dispatch(fetchPlanById(id!));
  }, [dispatch, id]);

  const handleDownloadExcel = () => {
    const data = [
      { Provisions: '', Answer: '' },
      ...plan.answers!.map((answer) => ({
        Provisions: answer.provisionLabel,
        Answer: answer.modelAnswer,
      })),
    ];

    const ws = XLSX.utils.json_to_sheet(data);

    // Make the title bold
    ws['A1'].s = {
      font: { bold: true },
    };
    // Make the title bold
    ws['A2'].s = {
      font: { bold: true },
    };

    // Auto-resize columns
    const colWidths = ['Provisions', 'Answer'].map((header) => ({
      wch: Math.max(...data.map((row) => (row[header] || '').toString().length)),
    }));
    ws['!cols'] = colWidths;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Provisions Answers');
    XLSX.writeFile(wb, 'provisions_answers.xlsx');
  };

  return (
    <div className='page-content'>
      <React.Fragment>
        <Container fluid>
          <Breadcrumbs title='My Plans' breadcrumbItem='Plan Review' />

          <Row>
            <Col xs={12}>
              <Card>
                {loading && <Spinners />}
                {/* TOP Section */}
                {!loading && plan && (
                  <Row className='my-3 px-3 d-flex align-items-center'>
                    <Col className='col-4 planDetail-top-section'>
                      {plan.documentName || plan.fileName}
                    </Col>
                    <Col className='col-2 planDetail-top-section'>
                      {'Uploaded: ' +
                        (plan.createdAt as Timestamp)?.toDate().toLocaleDateString('en', {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                        })}
                    </Col>
                    <Col className='col-2 planDetail-top-section'>
                      {getStatusDescription(plan.status)}
                    </Col>
                    <Col className='col-4'>
                      <div className='d-flex justify-content-end px-3'>
                        {plan.status !== 'archived' && (
                          <Button
                            type='button'
                            color='secondary'
                            className='btn mx-2 px-4'
                            onClick={handleArchivePlan}
                          >
                            Archive Plan
                          </Button>
                        )}
                        <Button
                          type='button'
                          color='secondary'
                          className='btn '
                          onClick={handleDownloadExcel}
                        >
                          Download to Excel
                        </Button>

                        <Button
                          type='button'
                          color='gray-400'
                          className='btn px-5 my-0 mx-2'
                          onClick={() => navigate(`/plans`)}
                        >
                          Back to My Plans
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
                {/* Provisions table */}
                {!loading && plan && <ProvisionsTable plan={plan} />}
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  );
};

export default PlanDetails;
