import { getFirebaseBackend } from '../../../firebase';

// action
import {
  apiErrorChange,
  registerUserFailed,
  registerUserSuccessful,
  resetRegisterFlagChange,
} from './reducer';

// Is user register successfull then direct plot user in redux.
export const registerUser = (user: any) => async (dispatch: any) => {
  try {
    const response = getFirebaseBackend().registerUser(user.email, user.password);
    const data: any = await response;
    dispatch(registerUserSuccessful(data));
  } catch (error) {
    dispatch(registerUserFailed(error));
  }
};

export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};

export const apiError = () => {
  try {
    const response = apiErrorChange('');
    return response;
  } catch (error) {
    return error;
  }
};
