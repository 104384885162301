//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../firebase';

// action
import { profileError, profileSuccess, resetProfileFlagChange } from './reducer';

export const editProfile = (user: any) => async (dispatch: any) => {
  try {
    const fireBaseBackend = getFirebaseBackend();
    const response = await fireBaseBackend.editProfileAPI(user.username);

    if (response) {
      dispatch(profileSuccess(user));
    }
  } catch (error) {
    dispatch(profileError(error));
  }
};

export const resetProfileFlag = () => {
  try {
    const response = resetProfileFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};
