import { Navigate } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';

// Auth
import ForgotPassword from 'pages/Authentication/ForgotPassword';
import Login from 'pages/Authentication/login';
import Logout from 'pages/Authentication/Logout';
import SignUp from 'pages/Authentication/Register';
import UserProfile from 'pages/Authentication/user-profile';
import PlanDetails from 'pages/PlanDetails';
import PlanComparison from 'pages/PlanDetails/comparisonPage';
import PlanList from 'pages/PlanList';

const authProtectedRoutes = [
  { path: '/plans', component: <PlanList /> },
  { path: '/dashboard', component: <Dashboard /> },
  { path: '/profile', component: <UserProfile /> },
  { path: '/plan/:id', component: <PlanDetails /> },
  { path: '/planComparison/:ids', component: <PlanComparison /> },

  { path: '/', exact: true, component: <Navigate to='/plans' /> },
];

const publicRoutes = [
  { path: '/login', component: <Login /> },
  { path: '/logout', component: <Logout /> },
  { path: '/forgot-password', component: <ForgotPassword /> },
  { path: '/register', component: <SignUp /> },
];
export { authProtectedRoutes, publicRoutes };
