"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PDAIHelper = void 0;
const provisions_1 = require("./provisions");
class PDAIHelper {
    static addLocationToExistingAnswers(locationData, existingAnswers) {
        const data = locationData.replace('```json', '').replace('```', '');
        console.log('addLocationToExistingAnswers (stringData): ' + data);
        const parsedLocationData = JSON.parse(data);
        console.log('addLocationToExistingAnswers (parsedData): ' + parsedLocationData);
        const existingAnswersEnrichedWithLocations = parsedLocationData.map((locationData) => {
            let newLocations = [];
            if (locationData.locations && locationData.locations.length > 0) {
                newLocations = [...locationData.locations];
            }
            return Object.assign(Object.assign({}, this.getProvisionAnswerFromLabel(locationData.provisionLabel, existingAnswers)), { locations: newLocations });
        });
        return existingAnswersEnrichedWithLocations;
    }
    static getJsonFromProvisionsData(provisionData) {
        const data = provisionData.replace('```json', '').replace('```', '');
        console.log('getJsonFromProvisionsTable: ' + data);
        let provisions = [];
        provisions = JSON.parse(data);
        provisions = provisions.map((provision) => {
            return Object.assign(Object.assign({}, provision), { provisionName: this.getProvisionNameFromLabel(provision.provisionLabel) });
        });
        return provisions;
    }
    static getProvisionNameFromLabel(provisionLabel) {
        for (const provision of provisions_1.PDAIProvisions) {
            if (provision.provisionLabel == provisionLabel) {
                return provision.provisionName;
            }
        }
        throw new Error(`Error getting provision name from label ${provisionLabel}`);
    }
    static getProvisionAnswerFromLabel(provisionLabel, existingAnswers) {
        for (const provisionAnswers of existingAnswers) {
            if (provisionAnswers.provisionLabel == provisionLabel) {
                return provisionAnswers;
            }
        }
        throw new Error(`Error getting provision name for location from label ${provisionLabel}`);
    }
}
exports.PDAIHelper = PDAIHelper;
