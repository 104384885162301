import React from 'react';

// Reactstrap

// Import menuDropdown
import ProfileMenu from '../../Components/CommonForBoth/TopBarDropDown/ProfileMenu';

// import images

//i18n
import { withTranslation } from 'react-i18next';

const Header = (props: any) => {
  function tToggle() {
    const body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  return (
    <React.Fragment>
      <header id='page-topbar'>
        <div className='navbar-header'>
          <div className='d-flex'>
            <button
              type='button'
              onClick={() => tToggle()}
              className='btn btn-sm px-3 font-size-16 header-item '
              id='vertical-menu-btn'
            >
              <i className='fa fa-fw fa-bars' />
            </button>
          </div>
          <div className='d-flex'>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default withTranslation()(Header);
